import Text from 'components/commons/text';
import { AlertTriangle } from 'react-feather';
import color from 'styles/color';

import { InputMessageContainer } from './style';

interface ErrorMessageInputProps {
  message?: React.ReactNode;
}

export default function ErrorMessageInput(props: ErrorMessageInputProps) {
  if (typeof props.message === 'undefined') return null;
  return (
    <InputMessageContainer>
      <AlertTriangle color={color.error} size={16} />
      <Text color="error" variant="body4">
        {props.message}
      </Text>
    </InputMessageContainer>
  );
}
