import Text from 'components/commons/text';
import React from 'react';
import { CheckCircle, Info } from 'react-feather';
import color from 'styles/color';

import { InputMessageContainer } from './style';

export interface HintMessageInputProps {
  status: HintStatusEnum;
  message?: string;
}

export enum HintStatusEnum {
  success = 'success',
  error = 'error',
  default = 'default',
  focus = 'focus',
  disabled = 'disabled',
}

export default function HintMessageInput(props: HintMessageInputProps) {
  const { icon, textColor } = React.useMemo(() => {
    switch (props.status) {
      case HintStatusEnum.success:
        return {
          icon: <CheckCircle color={color.success} size={16} />,
          textColor: 'success' as const,
        };
      case HintStatusEnum.error:
      case HintStatusEnum.default:
      case HintStatusEnum.focus:
      case HintStatusEnum.disabled:
        return {
          icon: <Info color={color.info} size={16} />,
          textColor: 'info' as const,
        };
    }
  }, [props.status]);

  if (typeof props.message === 'undefined') return null;
  if (props.status === HintStatusEnum.disabled) return null;

  return (
    <InputMessageContainer>
      {icon}
      <Text color={textColor} variant="body4">
        {props.message}
      </Text>
    </InputMessageContainer>
  );
}
