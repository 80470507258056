import * as React from 'react';
import * as Icons from 'react-feather';

import {
  Container,
  FieldContainer,
  IconButton,
  InputContainer,
  InputStyled,
  Label,
  PhoneCode,
} from './styles';
import ErrorMessageInput from '../error-message-input';
import HintMessageInput, { HintStatusEnum } from '../hint-message-input';
import { IPhoneCodeProps } from '../phone-code-select-input';

export interface ITextFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  hint?: string;
  errorMessage?: string;
  onRenderLeftComponent?: React.ReactNode;
  onRenderRightComponent?: React.ReactNode;
  phoneCodeProps?: IPhoneCodeProps;
  hideXIcon?: boolean;
  hideEyeIcon?: boolean;
  onXClick?: () => void;
}

const TextField = React.forwardRef<HTMLInputElement, ITextFieldProps>(
  (props, ref) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const fallbackRef = React.useRef<HTMLInputElement>(null);
    const domRef = ref || fallbackRef;
    const {
      label,
      hint,
      errorMessage,
      type,
      onRenderLeftComponent,
      onRenderRightComponent,
      phoneCodeProps,
      hideXIcon = false,
      hideEyeIcon = false,
      onXClick,
      ...rest
    } = props;
    const [state, setState] = React.useState<HintStatusEnum>(
      HintStatusEnum.default,
    );

    React.useEffect(() => {
      if (rest.disabled) {
        setState(HintStatusEnum.disabled);
        return;
      }

      if (errorMessage) {
        setState(HintStatusEnum.error);
        return;
      }

      if (rest.value) {
        setState(HintStatusEnum.success);
        return;
      }

      setState(HintStatusEnum.default);
    }, [errorMessage, rest.disabled, rest.value]);

    return (
      <Container>
        {label && <Label required={rest.required}>{label}</Label>}
        <FieldContainer>
          {type === 'phone' && (
            <PhoneCode {...phoneCodeProps} clearable={false} />
          )}
          <InputContainer variant={state as any}>
            {onRenderLeftComponent}
            <InputStyled
              ref={domRef}
              {...rest}
              disabled={rest.disabled}
              onFocus={(e) => {
                rest.onFocus && rest.onFocus(e);
                setState(HintStatusEnum.focus);
              }}
              onBlur={(e) => {
                rest.onBlur && rest.onBlur(e);
                if (e.target.value.length > 0) {
                  if (errorMessage) {
                    setState(HintStatusEnum.error);
                  } else {
                    setState(HintStatusEnum.success);
                  }
                } else {
                  setState(HintStatusEnum.default);
                }
              }}
              type={showPassword ? 'text' : type}
            />
            {!!rest.value && !hideXIcon && !rest.disabled && !rest.readOnly && (
              <IconButton
                type="button"
                css={{
                  color: '$disabled',
                  opacity: 0.5,
                  '&:hover': {
                    opacity: 1,
                  },
                }}
                onClick={() => {
                  if (fallbackRef.current) {
                    fallbackRef.current.value = '';
                    onXClick && onXClick();
                    setState(HintStatusEnum.default);
                  }
                  onXClick && onXClick();
                  setState(HintStatusEnum.default);
                }}
              >
                <Icons.X size={16} strokeWidth={3.5} />
              </IconButton>
            )}
            {!hideEyeIcon &&
              (!rest.disabled || !rest.readOnly) &&
              type === 'password' && (
                <IconButton
                  type="button"
                  css={{
                    color: '$disabled',
                    opacity: 0.5,
                    '&:hover': { opacity: 1 },
                  }}
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  {showPassword ? (
                    <Icons.Eye size={16} strokeWidth={3.5} />
                  ) : (
                    <Icons.EyeOff size={16} strokeWidth={3.5} />
                  )}
                </IconButton>
              )}
            {onRenderRightComponent}
          </InputContainer>
        </FieldContainer>
        <ErrorMessageInput message={errorMessage} />
        <HintMessageInput status={state} message={hint} />
      </Container>
    );
  },
);

TextField.displayName = 'TextField';

export default TextField;
