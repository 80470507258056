import IconBox from 'components/commons/icon-box';
import { Popper } from 'components/commons/popper';
import { format } from 'date-fns';
import { enUS, id } from 'date-fns/locale';
import { useRouter } from 'next/router';
import * as React from 'react';
import ReactCalendar, {
  CalendarProps as ReactCalendarProps,
} from 'react-calendar';
import * as Icons from 'react-feather';
import { styled } from 'styles';
import color from 'styles/color';
import typography from 'styles/typography';

import TextField from './text-field';

export interface IDatePickerProps
  extends ReactCalendarProps,
    Omit<
      React.HTMLAttributes<HTMLDivElement>,
      'className' | 'defaultValue' | 'onChange'
    > {
  label?: string;
  errorMessage?: string;
  disabled?: boolean;
  required?: boolean;
  minDate?: Date;
  onChange?: (value: any, event: any) => void;
  value?: any;
}

const StyledCalendar = styled(ReactCalendar, {
  ...typography.body4,
  borderRadius: 8,
  overflow: 'hidden',
  backgroundColor: 'white',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  minWidth: 280,
  maxWidth: 343,
  height: 'fit-content !important',
  '.react-calendar__tile': {
    textAlign: 'center',
    borderRadius: 4,
    border: '2px solid white',
    px: 2,
    py: 8,
    background: 'none',
  },
  '.react-calendar__viewContainer': {
    px: 4,
    pb: 2,
  },
  '.react-calendar__month-view__days__day--weekend': {
    color: '#d10000',
    ...typography.body4Semibold,
  },
  '.react-calendar__tile--now': {
    background: 'transparent',
    border: '2px solid $irisBlue',
  },
  '.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus':
    {},
  '.react-calendar__tile--active, .react-calendar__tile--active:hover': {
    borderColor: 'white',
    backgroundColor: 'red',
    color: 'white',
    opacity: 1,
  },
  '.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus':
    {
      backgroundColor: '$primary2',
      color: '$white',
    },
  '.react-calendar__month-view__weekdays__weekday': {
    display: 'flex',
    justifyContent: 'center',
    py: 4,
  },
  '.react-calendar__navigation__label__labelText': {
    height: 44,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    ...typography.body3Semibold,
  },
  '.react-calendar__navigation__label, .react-calendar__navigation__arrow': {
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    border: '3px solid white',
    borderRadius: 4,
    py: 2,
    minWidth: 45,
    fontSize: 28,
    height: 44,
    '&:enabled:hover, &:enabled:focus': {
      '> div': {
        backgroundColor: '$primary',
        '& > * ': {
          color: '$white',
        },
      },
    },
  },
  '.react-calendar__navigation': {
    height: 44,
    mb: 10,
    px: 4,
    display: 'flex',
  },
  '.react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile':
    {
      px: '0.5em',
      py: '2em',
    },
  '.react-calendar__month-view__days__day--neighboringMonth': {
    color: '#757575',
    background: 'transparent',
  },
  '.react-calendar button': {
    margin: 0,
    border: 0,
    outline: 'none',
    font: 'inherit',
    fontSize: '0.8em',
  },
  '.react-calendar__month-view__weekdays': {
    color: '#6F797B',
    textAlign: 'center',
    ...typography.body4Semibold,
    fontWeight: 600,
  },
  '.react-calendar__tile:disabled': {
    backgroundColor: '#f0f0f0',
    color: '$disabled',
    borderRadius: 0,
  },
  '.react-calendar__tile--range': {
    backgroundColor: '$primary',
    color: '$white',
  },
  '.react-calendar__tile--rangeStart, .react-calendar__tile--rangeEnd': {
    backgroundColor: '$primary',
    color: '$white',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: color.neutral1,
    },
  },
  '.react-calendar__navigation__label:enabled:hover': {},
  '.react-calendar__tile--hasActive': {
    borderColor: 'white',
    backgroundColor: '$primary',
    color: '$white',
    '&:enabled:hover, &:enabled:focus': {
      backgroundColor: '$primary3',
    },
  },
  '.react-calendar__month-view__weekdays__weekday > abbr': {
    textDecoration: 'none',
  },
});

const DateFormat = 'dd MMMM yyyy';

function DatePicker(props: IDatePickerProps, ref: typeof ReactCalendar) {
  const { locale } = useRouter();
  const {
    onChange,
    label,
    disabled,
    errorMessage,
    required,
    placeholder,
    minDate,
    ...rest
  } = props;
  const _value = React.useMemo(() => {
    if (props.value) {
      if (!Array.isArray(props.value)) {
        return format(props.value, DateFormat, {
          locale: locale === 'en' ? enUS : id,
        });
      }
    }
    return '';
  }, [locale, props.value]);
  return (
    <Popper
      trigger={(ref, toggle) => (
        <TextField
          value={_value}
          placeholder={placeholder}
          onBlur={rest.onBlur}
          onFocus={rest.onFocus}
          ref={ref}
          readOnly
          onClick={toggle}
          hideXIcon
          label={label}
          errorMessage={errorMessage}
          disabled={disabled}
          required={required}
          onRenderLeftComponent={
            <IconBox
              tabIndex={-1}
              css={{
                marginLeft: 16,
                cursor: 'default',
                '&:focus': {
                  outlineColor: 'transparent',
                },
              }}
            >
              <Icons.Calendar color={color.primary3} size={18} />
            </IconBox>
          }
        />
      )}
      childrenHasShadow={false}
    >
      {(toggle) => (
        <StyledCalendar
          {...rest}
          minDate={minDate}
          onChange={(value, event) => {
            onChange && onChange(value, event);
            toggle();
          }}
        />
      )}
    </Popper>
  );
}

export default React.forwardRef(DatePicker);
