import Text from 'components/commons/text';
import { styled } from 'styles';

export const InputMessageContainer = styled(Text, {
  marginTop: 4,
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'flex-start',
  gap: 4,
  svg: {
    marginTop: 2,
  },
});
